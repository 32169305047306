import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-no-data-found',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './no-data-found.component.html',
})
export class NoDataFoundComponent {}
