import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PopupComponent } from './shared/components/popup/popup.component';
import { filter } from 'rxjs';
import { IUserLocalization } from '@layout/models/layout-model';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    TourMatMenuModule,
    RouterModule,
    NgIf,
    MatMenuModule,
    MatIcon,
    MatCardModule,
    MatDialogModule,
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    public readonly tourService: TourService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const localization = this.languageService.getLocalization();
    if (!localization) {
      this.getDefaultLanguage();
    } else {
      this.setDefaultLanguage();
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const isOpen = JSON.parse(sessionStorage.getItem('isOpen') as string);
        if (
          !JSON.parse(isOpen) &&
          !this.router.url.includes('/login') &&
          !this.router.url.includes('/callback') &&
          !this.router.url.includes('/proxy') &&
          !this.router.url.includes('/register')
        ) {
          this.dialog.open(PopupComponent);
        }
      });
  }

  setDefaultLanguage() {
    const lang: string = this.languageService.getLanguage();
    this.translateService.setDefaultLang(lang);
  }
  getDefaultLanguage() {
    this.languageService.getUserLocalization().subscribe({
      next: (result: any) => {
        let language = result?.data?.getUserLocale[0];

        if (!language) {
          language = {
            Preferred_Language: this.languageService.getLanguage(),
            Timezone: this.languageService.getRegion(),
          } as IUserLocalization;
        }

        this.languageService.setLocalization(language);
        this.setDefaultLanguage();
      },
      error: (error: any) => {
        // this.errorMessage();
        let language = {
          Preferred_Language: this.languageService.getLanguage(),
          Timezone: this.languageService.getRegion(),
        } as IUserLocalization;
        this.languageService.setLocalization(language);
        this.setDefaultLanguage();
      },
    });
  }

  errorMessage() {
    sessionStorage.clear();
    // this.snackBar.open('Error');
    this.router.navigateByUrl('login');
  }
}
