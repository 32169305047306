<div id="popup" class="popup-container">
  <div class="popup-content">
    <h2>{{ "translation.App.apptitle" | translate }}</h2>
    <p class="content-new-tour">
      {{ "translation.HelpTour.popupcontent" | translate }}
    </p>
    <div class="explore">
      <a (click)="explore()">{{ "translation.helptour.text06" | translate }}</a>
    </div>
    <div class="popout-bottom">
      <div class="skipnow">
        <a (click)="skipForNow()">{{
          "translation.helptour.text07" | translate
          }}</a>
      </div>
      <div class="checkbox-container">
        <input type="checkbox" id="dontShowAgain" class="dontShowAgain" (click)="dontShowAgain()" />
        <label for="dontShowAgain">{{
          "translation.helptour.text08" | translate
          }}</label>
      </div>
    </div>
  </div>
</div>