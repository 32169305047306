<div id="no-data">
    <div class="fof">
        <h1>404</h1>
        <h2>No Data Found :(</h2>
        <p>
            <button class="navigate-button-align select-button" [routerLink]="'/'" id="dashboard">
                Go to Dashboard
            </button>
        </p>
    </div>
</div>