import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, finalize, throwError } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BayerInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (!req.url.endsWith('.json') && !req.url.endsWith('.svgr')) {
      const token = localStorage.getItem('accessToken');
      const reqUrl = `${environment?.apiUrl}/${req.url}`;
      req = this.getRequest(req, token, reqUrl);
    }
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (
          error?.status === 401 &&
          (error?.error?.detail === 'Invalid token' ||
            error?.error?.detail === 'Token expired')
        ) {
          alert('Your session has expired. Please log in again to continue.');
          localStorage.clear();
          window.location.href = '';
        }
        if (error?.status === 500) {
          console.log(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
      })
    );
  }

  getRequest(request: HttpRequest<any>, token: any, url: any) {
    const header = `Bearer ${token}`;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: header,
    });
    if (request.headers.has('isContentType')) {
      const acceptFileFormat: any = request?.headers?.get('Accept');
      headers = new HttpHeaders({
        Accept: acceptFileFormat,
        Authorization: header,
      });
      headers.set('Content-Type', 'multipart/form-data');
    }
    return request.clone({
      url,
      headers,
    });
  }
}
