import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { environment } from '@environments/environment';
declare const gigya: any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    TourMatMenuModule,
    RouterModule,
    MatMenuModule,
    MatIcon,
    MatCardModule,
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(public readonly tourService: TourService) {}

  ngOnInit(): void {
    // this.loadGigya();
  }

  loadGigya() {
    if (typeof gigya === 'undefined') {
      let node = document.createElement('script');
      node.src = `https://cdns.gigya.com/JS/gigya.js?apiKey=${environment.apiKey}`;
      node.type = 'text/javascript';
      node.lang = 'javascript';
      document.head.appendChild(node);
    }
  }
}
